import { lens } from '@owl-nest/config'
import { Lang } from '@owl-nest/api-client/latest'

type Environment = {
  UFE: {
    localeFiles: Record<Lang, string>
  }
}

const windowLens = lens<Environment>()

const global =
  typeof process !== 'undefined'
    ? {
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
        CURRENCIES: process.env.CURRENCIES ? JSON.parse(process.env.CURRENCIES) : undefined,
      }
    : window

export const LOCALE_FILES = windowLens.get('UFE').get('localeFiles').value(global, { trust: true })
