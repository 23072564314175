import { hexToRgb, shade } from '../utils/colors'

export { hexToRgb, shade }

/**
 * Colors palette
 *
 * Please respect these rules:
 * - all color constants should correctly named (human named)
 * - the named should be capitalized
 * - constant have to be exported
 * - constant must be sorted in a logical category
 */

// Shades of yellow
/** @deprecated Use SECONDARY_YELLOW_200 instead */
export const SECONDARY_STATE_YELLOW_200 = '#FFEAC9'
/** @deprecated Use SECONDARY_YELLOW_500 instead */
export const SECONDARY_STATE_YELLOW_500 = '#E9AE5A'
/** @deprecated Use SECONDARY_YELLOW_800 instead */
export const SECONDARY_STATE_YELLOW_800 = '#8A400B'

// Shades of blue
/** @deprecated Use PRIMARY_BLUE_200 instead */
export const BLUE_SHADE_1 = '#D1EDF7'
/** @deprecated Use PRIMARY_BLUE_900 instead */
export const BLUE_SHADE_2 = '#004E69'
/** @deprecated Use PRIMARY_BLUE_100 instead */
export const BLUE_SHADE_3 = '#E7F8FC'

// Shades of red
/** @deprecated Use PRIMARY_RED_200 instead */
export const RED_SHADE_1 = '#FDDCE3'
/** @deprecated Use PRIMARY_RED_900 instead */
export const RED_SHADE_2 = '#8B0020'

// Primary colors
/** @deprecated Use PRIMARY_GREY_900 instead */
export const PRIMARY_BLACK = '#232221'
/** @deprecated Use PRIMARY_GREY_700 instead */
export const PRIMARY_BLACK_HOVER = '#363636' // TODO: Update with new value?
/** @deprecated Use PRIMARY_BLUE_500 instead */
export const PRIMARY_BLUE = '#18A5D6'
/** @deprecated Use PRIMARY_BLUE_700 instead */
export const PRIMARY_BLUE_HOVER = '#007199'
/** @deprecated Use SECONDARY_GREEN_500 instead */
export const PRIMARY_GREEN = '#66DC88'
/** @deprecated Please reach out to Product team if this is used in a new design */
export const PRIMARY_GREEN_HOVER = '#64D088' // NOTE: Deprecated
/** @deprecated Use PRIMARY_RED_500 instead */
export const PRIMARY_RED = '#F01446'
/** @deprecated Use PRIMARY_RED_700 instead */
export const PRIMARY_RED_HOVER = '#BD0C33'
/** @deprecated Use PRIMARY_SAND_100 instead */
export const PRIMARY_SAND = '#F6F1EB'
/** @deprecated Use PRIMARY_GREY_000 instead */
export const PRIMARY_WHITE = '#FFFFFF'
/** @deprecated Use SECONDARY_YELLOW_800 instead */
export const PRIMARY_YELLOW = '#8A400B'

// Secondary colors
/** @deprecated Please reach out to Product team if this is used in a new design */
export const SECONDARY_YELLOW = '#F7C884' // NOTE: Deprecated
/** @deprecated Use BACKGROUND_ALERT instead */
export const SECONDARY_YELLOW_LIGHT = '#FEF4E7'
/** @deprecated Use BRAND_INDIGO instead */
export const SECONDARY_INDIGO = '#3E51B5'
/** @deprecated Please reach out to Product team if this is used in a new design */
export const SECONDARY_PURPLE = '#8B66C5' // NOTE: Deprecated
/** @deprecated Use BORDER_ERROR instead */
export const SECONDARY_RED = '#FF4336'
/** @deprecated Use SECONDARY_RED_200 instead */
export const SECONDARY_RED_LIGHT = '#FFECEB'
/** @deprecated Please reach out to Product team if this is used in a new design */
export const SECONDARY_PINK = '#FF5858' // NOTE: Deprecated
/** @deprecated Use BACKGROUND_SUCCESS instead */
export const SECONDARY_GREEN = '#E7F9EC'
/** @deprecated Please reach out to Product team if this is used in a new design */
export const SECONDARY_GREEN_HOVER = '#067366' // NOTE: Deprecated
/** @deprecated Use SECONDARY_MINT_700 instead */
export const SECONDARY_MINT = '#2E8D87'
/** @deprecated Use SECONDARY_MINT_300 instead */
export const SECONDARY_GREEN_LIGHT = '#D9F8F6'

// Shades of grey
/** @deprecated Use TEXT_SECONDARY instead */
export const GREY_SHADE_1 = '#59544E'
/** @deprecated Use TEXT_TERTIARY instead */
export const GREY_SHADE_2 = '#807974'
/** @deprecated Please reach out to Product team if this is used in a new design */
export const GREY_SHADE_3 = '#999999' // Note: Deprecated
/** @deprecated Use PRIMARY_GREY_500 instead */
export const GREY_SHADE_4 = '#BDBDBD'
/** @deprecated Use PRIMARY_SAND_200 instead */
export const GREY_SHADE_5 = '#E3DDD3'
/** @deprecated Use SECONDARY_GREY_400 instead */
export const GREY_SHADE_6 = '#F5F6F9'
/** @deprecated Use PRIMARY_SAND_500 instead */
export const GREY_SHADE_7 = '#B8AFA4'

// Brand accent colors
/** @deprecated Use SECONDARY_GREEN_800 instead */
export const BRAND_ACCENT_GREEN = '#02715C'
/** @deprecated Please reach out to Product team if this is used in a new design */
export const BRAND_ACCENT_GREEN_HOVER = '#005444' // Note: Deprecated
/** @deprecated Use BRAND_PURPLE instead */
export const BRAND_ACCENT_PURPLE = '#9E3F78'
/** @deprecated Use BRAND_YELLOW instead */
export const BRAND_ACCENT_YELLOW = '#FDCE03'

// Social colors
/** @deprecated Use TERTIARY_FACEBOOK instead */
export const FACEBOOK = '#3B5998'
/** @deprecated Use TERTIARY_MESSENGER instead */
export const MESSENGER = '#057FF5'
/** @deprecated Use TERTIARY_TWITTER instead */
export const TWITTER = '#55ACEE'
/** @deprecated Use TERTIARY_INSTAGRAM instead */
export const INSTAGRAM = '#E1306C'
/** @deprecated Use TERTIARY_LINKEDIN instead */
export const LINKEDIN = '#0077B5'
/** @deprecated Use TERTIARY_YOUTUBE instead */
export const YOUTUBE = '#E52D27'
/** @deprecated Use TERTIARY_WHATSAPP instead */
export const WHATSAPP = '#2DBEA5'
/** @deprecated Use TERTIARY_BANDCAMP instead */
export const BANDCAMP = '#60A8B9'
/** @deprecated Use TERTIARY_SOUNDCLOUD instead */
export const SOUNDCLOUD = '#FF4300'
/** @deprecated Use TERTIARY_SPOTIFY instead */
export const SPOTIFY = '#1ED660'
/** @deprecated Use TERTIARY_TWITCH instead */
export const TWITCH = '#6C2498'
/** @deprecated Use TERTIARY_VIMEO instead */
export const VIMEO = '#00B9D8'

// Helper for the colors utils
const PRIMARY_COLORS = {
  'primary-black': PRIMARY_BLACK,
  'primary-blue': PRIMARY_BLUE,
  'primary-red': PRIMARY_RED,
  'primary-white': PRIMARY_WHITE,
  // NOTE: Deprecated
  'primary-green': PRIMARY_GREEN,
}

const PRIMARY_HOVER = {
  'primary-black': PRIMARY_BLACK_HOVER,
  'primary-blue': PRIMARY_BLUE_HOVER,
  'primary-red': PRIMARY_RED_HOVER,
  // NOTE: Deprecated
  'primary-green': PRIMARY_GREEN_HOVER,
}

const PRIMARY_HOVER_HEX = {
  [PRIMARY_BLACK.toLowerCase()]: PRIMARY_BLACK_HOVER,
  [PRIMARY_BLUE.toLowerCase()]: PRIMARY_BLUE_HOVER,
  [PRIMARY_RED.toLowerCase()]: PRIMARY_RED_HOVER,
  // NOTE: Deprecated
  [PRIMARY_GREEN.toLowerCase()]: PRIMARY_GREEN_HOVER,
}

// Shadows
export const DROP_SHADOW = 'rgba(35, 34, 33, 0.1)'

// Utils
export const primary = (props) => {
  const primaryColor = props.theme && props.theme.colors && props.theme.colors.primary
  if (primaryColor && primaryColor.charAt(0) === '#') {
    return primaryColor
  }

  if (PRIMARY_COLORS[primaryColor]) {
    return PRIMARY_COLORS[primaryColor]
  }

  return PRIMARY_BLUE
}

export const primaryHover = (props) => {
  const color = props.theme && props.theme.colors && props.theme.colors.primary

  if (!color) {
    return PRIMARY_BLUE_HOVER
  }

  if (PRIMARY_HOVER[color]) {
    return PRIMARY_HOVER[color]
  }

  const lowerCaseColor = color.toLowerCase()
  if (lowerCaseColor && lowerCaseColor.charAt(0) === '#') {
    if (PRIMARY_HOVER_HEX[lowerCaseColor]) {
      return PRIMARY_HOVER_HEX[lowerCaseColor]
    }

    return hexToRgb(lowerCaseColor, 0.85)
  }

  return PRIMARY_BLUE_HOVER
}

export * from './neue/colors'
