export type RecursivePartial<TYPE> = TYPE extends (infer U)[]
  ? RecursivePartial<U>[]
  : TYPE extends object
  ? {
      [K in keyof TYPE]?: RecursivePartial<TYPE[K]>
    }
  : TYPE

export type LensOption<TRUST extends boolean, FORGIVE extends boolean> = {
  trust?: TRUST
  forgive?: FORGIVE
  silent?: boolean
}

export type Lens<OBJECT> = [OBJECT] extends [object]
  ? {
      get: <KEY extends keyof OBJECT>(key: KEY) => Lens<OBJECT[KEY]>
      value: <OPTION extends LensOption<boolean, boolean>>(
        root: unknown,
        option?: OPTION,
      ) =>
        | (OPTION['trust'] extends true ? OBJECT : RecursivePartial<OBJECT>)
        | (OPTION['forgive'] extends true ? undefined : never)
    }
  : {
      value: <OPTION extends LensOption<boolean, boolean>>(
        root: unknown,
        option?: OPTION,
      ) => OBJECT | (OPTION['forgive'] extends true ? undefined : never)
    }

export function lens<OBJECT>(path: (string | number | symbol)[] = []): Lens<OBJECT> {
  return {
    get: <KEY extends keyof OBJECT>(key: KEY) => lens([...path, key]),
    value: (root: unknown, option: LensOption<boolean, boolean> = {}) => {
      try {
        return safeGet(root, path)
      } catch (error) {
        if (option.forgive === true) {
          if (option.silent !== true) {
            console.warn(error)
          }
          return undefined
        }
        throw error
      }
    },
  } as any
}

export function safeGet<RESULT>(object: any, path: (string | number | symbol)[]): RecursivePartial<RESULT> {
  let current_path = '<root>'

  try {
    let current = object
    for (const [i, key] of path.entries()) {
      current_path += `.${String(key)}`
      if (Array.isArray(current)) {
        if (typeof key !== 'number') {
          throw Error(`can't find non number key in array (at ${current_path})`)
        }
        if (key >= current.length) {
          throw Error(`can't find index exceeding array length (at ${current_path})`)
        }
        current = current[key]
      } else if (typeof current === 'object') {
        if (!(typeof key === 'string' || typeof key === 'symbol')) {
          throw Error(`can't find number key in object (at ${current_path})`)
        }
        if (!(key in current)) {
          throw Error(`can't find key in object (at ${current_path})`)
        }
        current = current[key]
      } else {
        throw Error(`trying to index something that is neither array nor object (at ${current_path})`)
      }
    }
    return current
  } catch (cause: unknown) {
    throw new Error(`can't find path ${path.join('.')} in object : ${String(cause)}`)
  }
}
