declare global {
  interface Window {
    fbAsyncInit: () => void
  }

  const FB: {
    init: (config: any) => void
  }
}

type Config = {
  appId: string
  locale: string
}

export function init({ appId, locale }: Config) {
  if (typeof window === 'undefined') return

  const id = 'ul_fb_sdk'
  const element = document.getElementById(id)

  if (element !== null) {
    console.warn('Facebook SDK was already initialized')
    return
  }

  window.fbAsyncInit = () => {
    window.FB.init({
      appId,
      autoLogAppEvents: false,
      xfbml: true,
      version: 'v18.0',
    })
  }

  const script = document.createElement('script')
  script.id = id
  script.defer = true
  script.async = true
  script.src = `https://connect.facebook.net/${locale}/sdk.js`
  document.head.appendChild(script)
}
