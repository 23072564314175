import { createGlobalStyle, css } from 'styled-components'

import * as breakpoint from './constants/breakpoints'
import * as color from './constants/colors'
import * as font from './constants/fonts'

export const theme = {}

const lockScrollCss = css`
  .lock-scroll {
    @media not screen and ${breakpoint.TABLET} {
      position: absolute;
      overflow-y: hidden;
    }
    @media screen and ${breakpoint.TABLET} {
      top: initial;
      position: fixed;
      overflow-y: scroll;
    }
    width: 100%;
  }
`

export const LockScrollGlobalStyle = createGlobalStyle`
    ${lockScrollCss}
`

export const GlobalStyleDesignSystem = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    line-height: 1.375;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
  }

  body {
    background-color: ${color.PRIMARY_WHITE};
    color: ${color.PRIMARY_BLACK};
    font-family: ${font.PRIMARY_FONT};
    margin: 0;
    overflow-x: hidden;
  }

  abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  table {
    text-indent: 0;
    border-color: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1;
    margin: 0;
    color: inherit;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  legend {
    padding: 0;
  }

  progress {
    vertical-align: baseline;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  ::-webkit-search-cancel-button,
  ::-webkit-search-decoration,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    -webkit-appearance: none;
    display: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
    font-weight: normal;
  }

  button {
    background-color: transparent;
    background-image: none;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  /*
   * Use for sticky footer
   */
  #__next {
    height: 100%;
  }

  /*
   * Use for lock the scroll of the page to facilitate mobile navigation
   */
  .mobile-drawer-open {
    overflow-y: hidden;
    width: 100%;

    @media screen and ${breakpoint.TABLET} {
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }

  ${lockScrollCss}

`
