import { Message } from './messagesContext'

type CreateAction = {
  type: 'create'
  message: Message
}

type ClearAction = {
  type: 'clear'
  context: string
}

type SoftDeleteAction = {
  type: 'soft-delete'
  context: string
}

type InitAction = {
  type: 'default'
}

export type Action = CreateAction | ClearAction | InitAction | SoftDeleteAction

export type State = { [context: string]: Message }

export function messagesReducer(state: State, action: Action) {
  switch (action.type) {
    case 'create': {
      const { context } = action.message
      return {
        ...state,
        [context]: {
          ...action.message,
          deleted: false,
          timeOpen: action.message.timeOpen ?? 5000,
        },
      }
    }
    case 'soft-delete': {
      const context = action.context
      if (context !== undefined && state && state[context]) {
        return {
          ...state,
          [context]: {
            ...state[context],
            deleted: true,
          },
        }
      }
      break
    }
    case 'clear': {
      const context = action.context
      if (context !== undefined) {
        if (state && state[context]) {
          const { [context]: _, ...nextState } = state
          return nextState
        }
        break
      } else {
        // clear all messages
        return {}
      }
    }
    default: {
      return state
    }
  }
  return state
}
