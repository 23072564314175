// @ts-ignore
import { setTranslations, t, tc, tn, tnc, tp } from '@ulule/localize'

const INIT_CACHE: Record<string, Promise<any>> = {}

export async function memoizedInitializeLocalization(locale: string, localeFileUrl: string): Promise<void> {
  if (!(locale in INIT_CACHE)) {
    INIT_CACHE[locale] = fetchLocale(localeFileUrl)
  }

  try {
    const translations = await INIT_CACHE[locale]
    if (translations !== undefined) {
      setTranslations(translations)
    } else {
      // TODO: Sentry
      console.error(`Locale ${locale} cannot be loaded`)
      setTranslations({})
    }
  } catch (e) {
    setTranslations({})
  }
}

async function fetchLocale(localeFileUrl: string) {
  const response = await fetch(localeFileUrl)

  if (response.ok) {
    const translations = await response.json()
    return translations
  }
}

export async function initializeLocalization(locale: string, localeFileUrl: string): Promise<void> {
  try {
    const response = await fetch(localeFileUrl)

    if (response.ok) {
      console.info(`Locale ${locale} has been correctly loaded`)
      const translations = await response.json()
      setTranslations(translations)
    } else {
      // TODO: Sentry
      console.error(`Locale ${locale} cannot be loaded (error: ${response.statusText})`)
      setTranslations({})
    }
  } catch (e) {
    setTranslations({})
  }
}

export function tf(key: string, args?: any): any {
  if (process.env.NODE_ENV !== 'production') {
    console.warn('Missing translation key:', key)
  }
  return t(key, args)
}

export function tcf(key: string, args: any): any {
  if (process.env.NODE_ENV !== 'production') {
    console.warn('Missing translation key:', key)
  }
  return tc(key, args)
}

export function tnf(singular: string, plural: string, ...args: any[]): any {
  if (process.env.NODE_ENV !== 'production') {
    console.warn('Missing singular translation key:', singular)
    console.warn('Missing plural translation key:', plural)
  }
  return tn(singular, plural, ...args)
}

export function tncf(singular: string, plural: string, ...args: any[]): any {
  if (process.env.NODE_ENV !== 'production') {
    console.warn('Missing singular translation key:', singular)
    console.warn('Missing plural translation key:', plural)
  }
  return tnc(singular, plural, ...args)
}

export function tpf(key: string, context: any, args?: any): any {
  if (process.env.NODE_ENV !== 'production') {
    console.warn('Missing translation key:', key, 'for context:', context)
  }
  return tp(key, context, args)
}

// TODO: NOTE: This should eventually host @ulule/localize altogether
export { t, tc, tn, tnc, tp }
