'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setTranslations = exports.tnc = exports.tc = exports.tn = exports.tp = exports.t = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _jed = require('jed');

var _jed2 = _interopRequireDefault(_jed);

var _sprintfJs = require('sprintf-js');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var i18n = void 0;

var initTranslations = function initTranslations() {
  var translations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  i18n = new _jed2.default(translations);
};

var format = function format(string, args) {
  return (0, _sprintfJs.sprintf)(string, args);
};

var gettext = function gettext(string, args) {
  var trans = i18n.gettext(string);

  if (args) {
    trans = format(trans, args);
  }

  return trans;
};

var ngettext = function ngettext(singular, plural) {
  var count = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var args = arguments[3];

  var trans = i18n.ngettext(singular, plural, count);

  trans = format(trans, args || count);

  return trans;
};

var pgettext = function pgettext(key, context, args) {
  var trans = i18n.pgettext(context, key);

  if (args) {
    trans = format(trans, args);
  }

  return trans;
};

var renderComponent = function renderComponent(string, args) {
  var re = /\[([\w\d]+)(:(.[^\]]+))?\]/g;
  var children = [];

  function getPart() {
    return re.exec(string);
  }

  function addToChildren(text) {
    var dom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'span';

    var element = void 0;

    if (_react2.default.isValidElement(dom)) {
      element = _react2.default.cloneElement(dom, null, text && text.trim());
    } else {
      element = _react2.default.createElement(dom, null, text);
    }

    children.push(element);
  }

  var match = getPart();
  var lastIndex = 0;

  while (match && match.length) {
    var _match = match,
        _match2 = _slicedToArray(_match, 4),
        group = _match2[0],
        key = _match2[1],
        subgroup = _match2[2],
        text = _match2[3];

    var _match3 = match,
        input = _match3.input,
        index = _match3.index;

    var component = args && args[key];

    // create string before match [key: text]
    if (index > lastIndex) {
      addToChildren(input.substring(lastIndex, index));
    }

    // create string for match [key: text]
    if (component) {
      addToChildren(text && text.trim(), component);
    } else {
      addToChildren(group);
    }

    lastIndex = index + group.length;
    match = getPart();
  }

  // create string after last match
  if (lastIndex < string.length) {
    addToChildren(string.substring(lastIndex, string.length));
  }

  return _react2.default.createElement('span', null, children.map(function (child, key) {
    return _react2.default.cloneElement(child, { key: key });
  }));
};

// TODO
// - improve to use sprintf
// - try to not insert a root span element
// *****************
// const test = React.createElement('div', null, [
//   React.createElement('span', null, 'First string'),
//   React.createElement('span', null, 'second string'),
//   React.createElement('span', null, 'third string'),
// ]);
var tComponent = function tComponent(string, args) {
  var trans = gettext(string, args);
  return renderComponent(trans, args);
};

var tnComponent = function tnComponent(singular, plural) {
  var count = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var args = arguments[3];

  var trans = ngettext(singular, plural, count, args);
  return renderComponent(trans, args);
};

var t = exports.t = gettext;
var tp = exports.tp = pgettext;
var tn = exports.tn = ngettext;
var tc = exports.tc = tComponent;
var tnc = exports.tnc = tnComponent;
var setTranslations = exports.setTranslations = initTranslations;