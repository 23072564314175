export const LANGS = ['ca', 'de', 'en', 'es', 'fr', 'it', 'nl', 'pt'] as const

export const EXTENDED_LOCALES = {
  en: 'en_US',
  fr: 'fr_FR',
  pt: 'pt_BR',
  es: 'es_ES',
  it: 'it_IT',
  nl: 'nl_NL',
  de: 'de_DE',
  ca: 'es_ES',
}
