import type * as next from 'next/app'
import * as React from 'react'
import { createGlobalStyle } from 'styled-components'

import * as fb from '@owl-nest/facebook-sdk-init'
import * as hooks from '@owl-nest/hooks'
import * as messages from '@owl-nest/messages'
import { SuspendedLocationInitializer } from '@owl-nest/shared-next'
import * as plume from '@ulule/owl-kit-components/next'

import { EXTENDED_LOCALES } from '../constants/langs'

export default function App(appProps: next.AppProps): React.ReactElement {
  const currentLocale = (appProps.router as hooks.NextRouter).locale ?? appProps.router.defaultLocale

  React.useEffect(() => {
    if (typeof process.env.NEXT_PUBLIC_FACEBOOK_APP_ID === 'string') {
      fb.init({ appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || '', locale: EXTENDED_LOCALES[currentLocale] })
    }
  }, [])

  return (
    <>
      <GlobalStyle />
      <plume.LockScrollGlobalStyle />
      <SuspendedLocationInitializer router={appProps.router as hooks.NextRouter}>
        <messages.MessagesProvider>
          <appProps.Component {...appProps.pageProps} />
        </messages.MessagesProvider>
      </SuspendedLocationInitializer>
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: sofia-pro, sans-serif;
    margin: 0;
    padding-top: 0;
    text-rendering: optimizeLegibility;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
`
